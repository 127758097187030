import React from "react"
import { Auth0Provider } from '@auth0/auth0-react';
import { navigate } from 'gatsby';
import { Provider } from 'react-redux';
import store from './src/state/store';
import { ChakraProvider } from "@chakra-ui/react";
import "@fontsource/amaranth/400.css"
import "@fontsource/dancing-script/400.css"
import "@fontsource/josefin-sans/400.css"
import "@fontsource/open-sans/300.css"
import "@fontsource/raleway/400.css"

const onRedirectCallback = (appState) => {
  navigate(appState?.returnTo || '/', { replace: true });
};

export const wrapRootElement = ({ element }) => {
  return (
    <Auth0Provider
      domain={process.env.GATSBY_AUTH0_DOMAIN}
      clientId={process.env.GATSBY_AUTH0_CLIENTID}
      audience={process.env.GATSBY_AUTH0_API_IDENTIFIER}
      redirectUri={process.env.GATSBY_AUTH0_CALLBACK}
      onRedirectCallback={onRedirectCallback}
      scope="openid profile email"
    >
      <Provider store={store}>
        <ChakraProvider>
          {element}
        </ChakraProvider>
      </Provider>
    </Auth0Provider>
  );
};
