import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { ApiInformationModel } from '../../models/apiInformationModel';
import { API_SERVER_URL } from '../../utils/constants';
import { AppThunk, RootState } from '../store';

interface AppRootState {
  version?: string;
}

const initialState: AppRootState = {
  version: undefined
};

// Slice
const appRootSlice = createSlice({
  name: 'appRoot',
  initialState,
  reducers: {
    setAppVersion: (state, action: PayloadAction<string | undefined>) => {
      const { payload } = action;
      state.version = payload;
    }
  }
});

// Reducers
export default appRootSlice.reducer;

// Selectors
export const appRootSelector = (state: RootState) => state.appRoot;

// Actions
const { setAppVersion } = appRootSlice.actions;

// Thunks
export const thunkSetAppVersion = (): AppThunk => async (dispatch: Dispatch) => {  
  try {      
    const response = await axios.get<ApiInformationModel>(`${API_SERVER_URL}/api/status`);
    const responseData = response.data.version;
    dispatch(setAppVersion(responseData));
  }catch(eror){
    console.error("Couldn't query app version. Back-end services unavailable.");
  }
};