// 1. Import the extendTheme function
import { extendTheme } from "@chakra-ui/react";

// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
  brand:{
    100: "#555"
  }
}

const fonts = {
  heading: "Amaranth",
  body: "Josefin Sans",
}

const theme = extendTheme({ 
  colors, 
  fonts 
})

export default theme;